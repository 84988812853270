import React from "react";
import { Typography } from "@shared/ui/Typography";
import styled from "styled-components";
import { ErrorIcon } from "public/static/icons";

interface IProps {
  message: string;
}

export function ErrorMessage({ message }: IProps) {
  return (
    <StyledView>
      <ErrorIcon />
      <Typography color="secondary_error" fontSize="1.4rem">
        {message}
      </Typography>
    </StyledView>
  );
}

export const StyledView = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-top: 0.2rem;
`;
