import styled from "styled-components";
import React, { useState } from "react";
import { extractNumbers, setSpacesInNumber } from "@shared/lib";
import { useTranslation } from "next-i18next";
import { CalcRange } from "./CalcRange";
import { CalcInfo } from "./CalcInfo";

interface IProps {
  initSum: number;
  minSum: number;
  maxSum: number;
  stepSum: number;
  fromSum: string;
  toSum: string;

  initTerm: number;
  minTerm: number;
  maxTerm: number;
  stepTerm: number;
  fromTerm: string;
  toTerm: string;

  href: string;
  productId?: number;
  openContactModal?: () => void;
  rate?: number;
}

const calcMonthlyPaid = (
  term: number,
  sum: number,
  rate: number = 29
): number => {
  const rateMonth = ((rate / 360) * 30) / 100;
  return (
    ((rateMonth * (1 + rateMonth) ** term) / ((1 + rateMonth) ** term - 1)) *
    sum
  );
};

export function Calculator(props: IProps) {
  const { t } = useTranslation();
  const [sum, setSum] = useState(props.initSum);
  const [term, setTerm] = useState(props.minTerm);

  const monthlyPaid = calcMonthlyPaid(term, sum, props.rate);

  const overPay = monthlyPaid * term - sum;

  const handleChangeSum = (amount: string) => {
    setSum(extractNumbers(amount));
  };

  const handleChangeTerm = (amount: string) => {
    setTerm(extractNumbers(amount));
  };

  return (
    <StyledBottom>
      <StyledCalculatorRanges>
        <CalcRange
          title={t(`Сумма кредита`)}
          num={`${setSpacesInNumber(sum)} ₸`}
          from={props.fromSum}
          to={props.toSum}
          min={props.minSum}
          max={props.maxSum}
          step={props.stepSum}
          onChange={handleChangeSum}
          value={sum}
        />
        <CalcRange
          title={t(`Срок кредитования`)}
          num={t("{{term}} мес.", { term })}
          from={props.fromTerm}
          to={props.toTerm}
          min={props.minTerm}
          max={props.maxTerm}
          step={props.stepTerm}
          onChange={handleChangeTerm}
          value={term}
        />
      </StyledCalculatorRanges>
      <CalcInfo
        monthlyPay={`${setSpacesInNumber(Math.ceil(monthlyPaid))} ₸`}
        overPay={`${setSpacesInNumber(Math.ceil(overPay))} ₸`}
        openContactModal={props.openContactModal}
        href={props.href}
        productId={props.productId}
      />
    </StyledBottom>
  );
}

const StyledBottom = styled.div`
  display: grid;
  justify-content: space-between;
  grid-gap: 1.6rem;
  align-items: center;
  grid-template-columns: minmax(15rem, 54.4rem) minmax(30rem, 38.4rem);

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: 4.8rem;
  }
`;

const StyledCalculatorRanges = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
  grid-gap: 4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-gap: 4rem;
  }
`;
