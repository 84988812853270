import { IDictionaryOption } from "@shared/types";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import i18nextConfig from "../../next-i18next.config";

export const setSpacesInNumber = (num: number): string =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const extractNumbers = (text?: string): number =>
  Number(text?.replace(/\D/g, ""));

export const extractLetters = (text?: string): string =>
  text?.replace(/[^a-z]+/i, "");

export const extractAllExceptNumbers = (text?: string): string =>
  text?.replace(/[0-9]/g, "") ?? "";

export const splitMatchedText = (search, text) => {
  const regExp = new RegExp(search, "gi");
  return text.replace(
    regExp,
    (match) => `<span style="color: #B43432">${match}</span>`
  );
};

export const getI18nPaths = () =>
  i18nextConfig.i18n.locales.map((lng) => ({
    params: {
      locale: lng,
    },
  }));

export const extractSelectOptions = (
  list: IDictionaryOption[] | undefined,
  allOption?: string
): { label: string; value: string; loan_rate?: number }[] => {
  const options =
    list
      ?.map((item) => ({
        value: String(item.id),
        label: item.name,
        loan_rate: item.loan_rate,
      }))
      .sort((a, b) => Number(a.value) - Number(b.value)) ?? [];

  if (allOption) {
    options.unshift({ label: allOption, value: "", loan_rate: undefined });
  }

  return options;
};

export const calcMonthlyPaid = (
  term: number,
  sum: number,
  rate: number
): number => {
  const rateMonth = ((rate / 360) * 30) / 100;
  return (
    ((rateMonth * (1 + rateMonth) ** term) / ((1 + rateMonth) ** term - 1)) *
    sum
  );
};

export const getFormattedDateByRuLocale = (
  date: string,
  dateFormat: string,
  isNeedDateConstructor = false
) => {
  if (!date) return "";
  const parsingDate = isNeedDateConstructor ? new Date(date) : parseISO(date);

  return format(parsingDate, dateFormat);
};

export const getFormattedDate = (date: string) =>
  getFormattedDateByRuLocale(date, "dd.MM.yyyy - HH:mm", true);
