import React from "react";
import styled, { css } from "styled-components";

interface IProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  min: number;
  max: number;
  value: number;
  step?: number;
  className?: string;
  secondTrackVisible?: boolean;
}

export function Range({
  onChange,
  min,
  max,
  value,
  step = 1,
  secondTrackVisible = false,
  className,
}: IProps) {
  return (
    <Input
      type="range"
      style={
        {
          "--min": min,
          "--max": max,
          "--val": value,
        } as React.CSSProperties
      }
      onChange={onChange}
      max={max}
      min={min}
      step={step}
      value={value}
      $secondTrackVisible={secondTrackVisible}
      className={className}
    />
  );
}

const trackH = "1rem";
const thumbD = "3.2em";

const track = css`
  box-sizing: border-box;
  border: none;
  height: 1rem;
  background: ${(props) => props.theme.colors.primary_light_gray}33;
  border-radius: 0 0.8rem;
`;

const trackFill = css<{ $secondTrackVisible: boolean }>`
  ${track};
  ${(props) => css`
    background-image: linear-gradient(
        ${props.theme.colors.primary_red},
        ${props.theme.colors.primary_red}
      ),
      linear-gradient(
        ${props.theme.colors.primary_light_gray}${props.$secondTrackVisible ? "33" : "00"},
        ${props.theme.colors.primary_light_gray}${props.$secondTrackVisible ? "33" : "00"}
      );
  `}
  height: 1rem;
  background-color: transparent;

  background-size: var(--sx) 1rem, calc(100% - var(--sx)) 1rem;
  background-position: left center, right center;
  background-repeat: no-repeat;
`;

const fill = css`
  height: 1rem;
  background: ${(props) => props.theme.colors.primary_light_gray};
  border-radius: 4px;
`;

const thumb = css`
  box-sizing: border-box;
  border: none;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: ${(props) => props.theme.shadow["10dp"]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("/static/red_dot.svg");
  background-position: center center;
  background-repeat: no-repeat;
`;

const Input = styled.input<{ $secondTrackVisible: boolean }>`
  width: 100%;
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &:focus::-webkit-slider-thumb {
    //outline: -webkit-focus-ring-color auto 5px;

    box-shadow: ${(props) => props.theme.shadow["20dp"]};
  }

  &:focus::-moz-range-thumb {
    transition: box-shadow 0.3s ease;
    width: 3.6rem;
    height: 3.6rem;
    box-shadow: ${(props) => props.theme.shadow["20dp"]};
    //outline: -webkit-focus-ring-color auto 5px;
  }

  &:focus::-ms-thumb {
    transition: box-shadow 0.3s ease;
    width: 3.6rem;
    height: 3.6rem;
    box-shadow: ${(props) => props.theme.shadow["20dp"]};
    //outline: -webkit-focus-ring-color auto 5px;
  }

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * ${thumbD} + var(--ratio) * (100% - ${thumbD}));

  margin: 0;
  padding: 0;
  height: ${thumbD};
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &::-webkit-slider-runnable-track {
    ${trackFill};
  }

  &::-moz-range-track {
    ${track};
  }

  &::-ms-track {
    ${track};
  }

  &::-moz-range-progress {
    ${fill};
  }

  &::-ms-fill-lower {
    ${fill};
  }

  &::-webkit-slider-thumb {
    margin-top: calc(0.5 * (${trackH} - ${thumbD}));
    ${thumb};
    transition: box-shadow 0.3s ease;
  }

  &::-moz-range-thumb {
    ${thumb};
    transition: box-shadow 0.3s ease;
  }

  &::-ms-thumb {
    ${thumb};
    margin-top: 0;
    transition: box-shadow 0.3s ease;
  }

  &::-ms-tooltip {
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }
`;
