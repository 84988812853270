import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import React from "react";
import { Button } from "@shared/ui/Button";
import Link from "next/link";
import { InfoCalculatorIcon } from "public/static/icons";
import { useTranslation } from "next-i18next";

interface IProps {
  monthlyPay: string;
  overPay: string;
  href: string;
  productId?: number;
  openContactModal?: () => void;
}

export function CalcInfo(props: IProps) {
  const { t } = useTranslation();

  return (
    <StyledView>
      <StyledBlock>
        <StyledTitle fontSize="2rem">{t(`Ежемесячный платеж`)}</StyledTitle>
        <Typography fontSize="3.2rem" weight={700}>
          {props.monthlyPay}
        </Typography>
      </StyledBlock>
      <StyledBlock>
        <StyledTitle fontSize="2rem">{t(`Переплата`)}</StyledTitle>
        <Typography fontSize="3.2rem" weight={700} lineHeight={125}>
          {props.overPay}
        </Typography>
      </StyledBlock>
      <Link
        href={{ pathname: props.href, query: { productId: props.productId } }}
      >
        <StyledButton onClick={props?.openContactModal}>
          {t(`Оформить заявку`)}
        </StyledButton>
      </Link>
      <StyledWarning>
        <InfoCalculatorIcon />
        <Typography fontSize="1.4rem" color="basic_black">
          {t(
            `Результат является предварительным. Полная стоимость рассчитывается индивидуально.`
          )}
        </Typography>
      </StyledWarning>
    </StyledView>
  );
}

const StyledView = styled.div`
  padding: 4rem 2.4rem 2.8rem 2.4rem;
  border: 0.5px solid ${(props) => props.theme.colors.primary_light_gray};
  border-radius: 1.2rem;
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
  box-shadow: ${(props) => props.theme.shadow["20dp"]};
`;

const StyledBlock = styled.div`
  margin-bottom: 3.2rem;
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
`;

const StyledTitle = styled(Typography)`
  margin-bottom: 0.8rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    margin-bottom: 1.2rem;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    margin-top: 1.6rem;
  }
`;

const StyledWarning = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.8rem;
  opacity: 0.5;
`;
