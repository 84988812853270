import styled, { css } from "styled-components";
import { ErrorMessage } from "@shared/ui/ErrorMessage";
import { HTMLMotionProps, motion } from "framer-motion";
import React, { Ref } from "react";

interface IProps extends HTMLMotionProps<"input"> {
  errorMessage?: string;
  hasError?: boolean;
}

export const Input = React.forwardRef(
  ({ errorMessage, hasError, ...props }: IProps, ref) => (
    <StyledView>
      <StyledInput
        {...props}
        $hasError={hasError}
        ref={ref as Ref<HTMLInputElement>}
      />
      {hasError && errorMessage && <ErrorMessage message={errorMessage} />}
    </StyledView>
  )
);

const StyledView = styled(motion.div)`
  max-width: 100%;
  display: grid;
  justify-content: stretch;
`;

const StyledInput = styled(motion.input)<{ $hasError: IProps["hasError"] }>`
  ${(props) => css`
    padding: 1.6rem;
    border-radius: 0 1.2rem;
    border: 1px solid
      ${props.$hasError
        ? props.theme.colors.secondary_error
        : props.theme.colors.primary_light_gray};
    background-color: ${props.theme.colors.basic_white};
    font-size: 1.6rem;
    outline: none;
    &::placeholder {
      color: ${props.theme.colors.primary_light_gray};
      font-size: 1.6rem;
    }
    &:focus {
      border-color: ${props.$hasError
        ? props.theme.colors.secondary_error
        : props.theme.colors.primary_red};
    }
  `}
`;
