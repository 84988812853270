import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { Range } from "@shared/ui/Range";
import { Input } from "@shared/ui/Input";
import React, { useEffect, useState } from "react";
import { extractNumbers } from "@shared/lib";

interface IProps {
  title: string;
  num: string;
  from: string;
  to: string;
  min: number;
  max: number;
  step?: number;
  onChange: (value: string) => void;
  value: number;
}

export function CalcRange(props: IProps) {
  const [inputValue, setInputValue] = useState(props.value);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(Number(extractNumbers(e.currentTarget.value)));
  };

  const checkNum = (value: number) => {
    if (value < props.min) {
      setInputValue(props.min);
      return props.onChange(String(props.min));
    }
    if (value > props.max) {
      setInputValue(props.max);
      return props.onChange(String(props.max));
    }
    return props.onChange(String(value));
  };

  const onBlur = () => {
    checkNum(inputValue);
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      checkNum(inputValue);
    }
  };

  useEffect(() => {
    if (inputValue !== props.value) {
      setInputValue(props.value);
    }
  }, [props.value]);

  return (
    <div>
      <StyledHeader>
        <Typography weight={500} fontSize="2.4rem">
          {props.title}
        </Typography>
        <Typography weight={500} fontSize="2.4rem">
          {props.num}
        </Typography>
      </StyledHeader>
      <StyledInput
        value={inputValue}
        onChange={handleInputChange}
        onBlur={onBlur}
        onKeyPress={onEnterPress}
      />
      <StyledRange
        max={props.max}
        min={props.min}
        value={props.value}
        onChange={(e) => props.onChange(e.currentTarget.value)}
        step={props.step}
      />
      <StyledFooter>
        <Typography color="primary_light_gray">{props.from}</Typography>
        <Typography color="primary_light_gray">{props.to}</Typography>
      </StyledFooter>
    </div>
  );
}

const StyledHeader = styled.div`
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    margin-bottom: 0.4rem;
  }
`;

const StyledFooter = styled.div`
  margin-top: 0.4rem;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  border-radius: 0 1.2rem 0 0.4rem;
`;

const StyledRange = styled(Range)`
  margin-top: -1.7rem;
`;
